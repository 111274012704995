<template>
  <div>
    <vx-card class="mb-1">
       <vs-row vs-type="flex" vs-justify="space-between" >
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">                            
              <label class="vs-input--label mr-2">Nome</label>
              <vs-input class="mb-4 md:mb-0 w-full" v-model="filtro.nome" @keyup.enter="fetchLista(filtro)" />
              <label class="vs-input--label mr-2">CPF/CNPJ</label>
              <vs-input class="mb-4 md:mb-0 w-full" v-model="filtro.cpfCNPJ" @keyup.enter="fetchLista(filtro)"/>          
              <label class="vs-input--label mr-2">Status</label>
              <v-select                                               
                :options="CONSTANTE_STATUS_CLIENTE" 
                v-model="filtro.status"                      
                class="w-full vs-input--label">
              </v-select>   
          </vs-col>
      </vs-row>
     
      <vs-row vs-type="flex" vs-justify="space-between" class="mt-2">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12">
          <vs-button class="mr-2" color="primary" type="filled" icon-pack="feather" icon="icon-search" @click="fetchLista(filtro)"></vs-button>
          <vs-button class="mr-2" color="dark" type="border" icon-pack="feather" icon="icon-file" @click="limparFiltros"></vs-button>  
          <vs-button class="mr-2" color="warning" type="filled" icon-pack="feather" icon="icon-plus" @click="routeNovoRegistro"></vs-button>          
          <vs-button color="rgb(11, 189, 135)" type="filled" icon-pack="feather" icon="icon-save" @click="exportarParaCSV" v-if="habilitarExportacao"></vs-button>          
        </vs-col>
      </vs-row>
      
    </vx-card>

     
    <h6 class="mt-2 text-danger"> {{dadosDaGrid.length }} registros </h6>

    <ag-grid-vue
      ref="agGridTable"           
      :gridOptions="configuracaoGrid.gridOptions"
      class="ag-theme-material ag-grid-table ag-grid-altura mt-2"
      :columnDefs="configuracaoGrid.columnDefs"
      :defaultColDef="configuracaoGrid.defaultColDef"
      :rowData="dadosDaGrid"
      :rowHeight="20"
      :headerHeight="25"        
      rowSelection="single"
      colResizeDefault="shift"
      @row-selected="onRowSelectedRegistro"    
      :animateRows="true"
      :floatingFilter="false"
      :pagination="false"
      :paginationAutoPageSize="false"
      :suppressPaginationPanel="true"
      >
    </ag-grid-vue>      
    
  </div>

</template>

<script>
import axios from "@/axios.js"
import { AgGridVue } from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { CONSTANTE_STATUS_CLIENTE } from '@/constantesComboBox.js'

const URL_SERVLET = "/ClientesRest";
const NAME_ROUTE_EDIT = "cadastro-clientes-editar";

export default {
  components: {
    AgGridVue
  },
  data() {
    return {
      filtro : {codigo: null, serial:  null, cpfCNPJ: null},
      configuracaoGrid: { 
          gridApi: null, gridOptions: {}, 
          defaultColDef: { sortable: true, resizable: true, suppressMenu: true  },
          columnDefs: [       
            { headerName: "#", valueGetter: "node.rowIndex + 1", width: 30,  cellStyle: function() { return {'font-size': '10px'}; }  }, 
            { headerName: 'Código', field: 'codigoInterno' , width: 50},           
            { headerName: 'Nome', field: 'nome', width: 130 },           
            { headerName: 'Razão Social', field: 'razaoSocial', width: 120 },           
            { headerName: 'CNPJ/CPF', field: 'CNPJCPF', width: 100 },           
            { headerName: 'Status', field: 'status', width: 90 },           
            { headerName: 'Acesso', field: 'dataHoraUltimoAcesso', width: 110 },           
            { headerName: 'Contrato', field: 'statusContrato', width: 80 },           
            { headerName: 'Tipo', field: 'tipoContrato', width: 75},           
            { headerName: 'Início', field: 'dataInicioContrato', width: 75},           
            { headerName: 'Fim', field: 'dataFimContrato', width: 75},           
            { headerName: 'Versão', field: 'versaoProduto', width: 75},           
          ]
      },
      dadosDaGrid: [],
      totalRegistros: 0      
    }
  },  
  computed:{
      habilitarExportacao() {
          let perfilLogado = this.$store.state.AppActiveUser.perfil;
          if (perfilLogado === "ATIVADOR" || perfilLogado === "CONFIGURADOR" || perfilLogado === "SUPERVISOR" || perfilLogado === "FINANCEIRO" 
          || perfilLogado === "MONITOR_PANICO" || perfilLogado === "EMPRESA_MONITOR_PANICO" || perfilLogado === "EMPRESA_SUPERVISOR" || perfilLogado === "EMPRESA_ATIVADOR") {
              return false;
          }

          return true;
      }
  },
  created() {
      this.CONSTANTE_STATUS_CLIENTE = CONSTANTE_STATUS_CLIENTE;
  },
  methods: {
    limparFiltros() {
      this.filtro = {status: null, nome:  null, cpfCNPJ: null};      
    },
    fetchLista(pFiltro) {     
      this.$vs.loading() 
      const params = new URLSearchParams();
      params.append('method', 'FindAll');
      params.append('orderDirection', 'ASC');
      params.append('outputFormat', 'JSON');
      
      if (pFiltro.nome) {
        params.append('filter1', pFiltro.nome);
      }

      if (pFiltro.cpfCNPJ) {
        params.append('filter2', pFiltro.cpfCNPJ);
      }

      if (pFiltro.status) {
        params.append('filter', pFiltro.status.data);
      }

      if (pFiltro.numeroGSM) {
        params.append('contratoOuTeste', pFiltro.numeroGSM);
      }

      axios.post(URL_SERVLET, params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          this.$vs.loading.close();
          if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
              this.$router.push({ path: '/' });
          } else {
              this.dadosDaGrid = response.data;         
              this.totalRegistros = this.dadosDaGrid.length;                              
          }
      })
      .catch((error) => { 
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
      })    
                        
    },
    routeNovoRegistro() {
      this.$store.dispatch('setFiltroCadastroClientes', this.filtro);

      let self_ = this;

      self_.$router.push({
            name: NAME_ROUTE_EDIT                     
      })
    },
    onRowSelectedRegistro(evento) {
      this.$store.dispatch('setFiltroCadastroClientes', this.filtro);

      let self_ = this;
      let registro = evento.node.data;
      
      self_.$router.push({
            name: NAME_ROUTE_EDIT,
            params: {
              registroOriginal: registro
            }
      })

    },
    exportarParaCSV() {
      var params = {columnSeparator: ';', fileName: 'Clientes.csv'};
      this.configuracaoGrid.gridApi.exportDataAsCsv(params);
    }
    
  },
  mounted() {
    this.configuracaoGrid.gridApi = this.configuracaoGrid.gridOptions.api;
    this.configuracaoGrid.gridApi.sizeColumnsToFit();      
    if (this.$store.getters.filtroCadastroClientes) {
      this.filtro = this.$store.getters.filtroCadastroClientes;
    }     
    this.fetchLista(this.filtro);
    
  }
}

</script>
<style lang="scss" scoped>
  .ag-grid-altura{
    height: calc(100vh - 250px);
  }
</style>